<!--
   /**
      * cityPartner.vue
      * @module {src/modules/public}
      * @desc 前端api
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10">
                      <el-col :span="24">
                        <p class="bigTitle">城市合伙人</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">CITY PARTNER</p>
                      </el-col>

                    </el-row>
                    <el-row :gutter="10" class="margin_t_20">
                      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <el-row :gutter="10" class="margin_b_20">
                          <p class="textP">富融在每个城市招募优质的合伙人：</p>
                          <p class="textP">1、平台资源与合伙人分享，<span>共同成长</span>；</p>
                          <p class="textP">2、合伙人将<span>自身资源</span>引入平台，如中小物业、家政、生鲜等资源；</p>
                          <p class="textP">3、合伙人可引入更多的优质合伙人，实现运营团队、渠道的<span>自生长</span></p>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <el-row :gutter="10" class="margin_b_60">
                          <p class="textP">以广州为首个复制的城市，并辐射到国内不同的城市:</p>
                          <p class="textP">1、每个合伙人的加入，带来各种平台资源及更多的合伙人，带来<span>资源裂变</span>；</p>
                          <p class="textP">2、用户资源及消费资源的递增，带来<span>盈利裂变</span>。</p>
                        </el-row>
                      </el-col>

                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">服务费用</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">Service fee</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/fee.png" style="width:100px" /></p>
                        <p class="serviceS colorBlue">每项目每月￥200.00</p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">申请流程</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">APPLICATION PROCESS</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/one.png"/></p>
                        <p class="serviceS colorBlue">填申请表</p>
                        <p class="serviceM">填写城市合伙人申请表</p>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/two.png"/></p>
                        <p class="serviceS colorBlue">审核</p>
                        <p class="serviceM">审核通过后成为富融物创合伙人</p>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/three.png"/></p>
                        <p class="serviceS colorBlue">提交资料</p>
                        <p class="serviceM">合伙人提交物业公司、商户的资料，富融物创专人进行商户号的申请注册</p>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/four.png"/></p>
                        <p class="serviceS colorBlue">注册账号</p>
                        <p class="serviceM">合伙人以管理员身份在富融物创注册旗下物业公司、商户账号</p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  name: 'Login',
  components: {},
  data () {
    return {
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {

  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
body {
  background: #fff !important;
}
</style>
